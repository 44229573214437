//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  components: {
    TaskDetail: () => import("@/userview/task-manager/task-modal"),
  },
  props: {
    taskList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    teamValue: {
      type: Number,
      default: 0,
    },
    taskDetailPrefix: {
      type: String,
      default: "",
      require: true,
    },
  },
  data() {
    return {
      detailUrl: "",
      detailReferer: "",
      JId: null,
      taskDetailName: "taskDetailList",
      indexData: {
        type: "", // Add Edit
        name: "",
        xModalName: "",
      },
    };
  },
  methods: {
    xmodalloadSuccess() {
      if (this.teamValue && this.JId) {
        this.$modal.show(this.taskDetailName);
      }
    },
    xmodalbeforeOpen() {
      var self = this;
      if (this.teamValue && this.JId) {
        this.$http
          .post("/Task/GetTaskDetail.ashx", {
            teamId: JSON.parse(this.teamValue),
            Id: JSON.parse(this.JId),
          })
          .then((resp) => {
            if (resp.res == 0) {
              self.indexData = {
                type: `${self.isMember ? "" : "Edit"}`,
                name: `${self.isMember ? "任务详情" : "编辑任务"}`,
                departmentCode: self.JId,
                row: resp.data,
                xModalName: self.taskDetailName,
              };
              self.$nextTick(() => {
                self.$refs.taskM.changeEditState();
              });
            }
          });
      }
    },
    /**
     * 任务进度的进度条内容格式
     */
    formatProcess(val) {
      return `进度：${val}%`;
    },
    /**
     * 任务耗时进度条内容格式
     */
    formatSpendTime(task) {
      return `耗时：${task.SpendTime ? task.SpendTime.toFixed(1) : 0}h / ${
        task.EstimatedTime ? task.EstimatedTime : 0
      }h (${
        task.EstimatedTime
          ? task.SpendTime > task.EstimatedTime
            ? 100
            : ((task.SpendTime / task.EstimatedTime) * 100).toFixed(0)
          : 0
      }%)`;
    },
    //查看任务详情
    detail(id) {
      this.JId = id;
      this.detailUrl = `${this.taskDetailPrefix}/${id}`;
      this.detailReferer = this.taskDetailPrefix;
      this.$modal.show(this.taskDetailName);
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.teamValue && this.$route.params.jId) {
        this.JId = Number.parseInt(this.$route.params.jId);
        this.detailUrl = `${this.taskDetailPrefix}/${this.JId}`;
        this.detailReferer = this.taskDetailPrefix;
        this.$modal.show(this.taskDetailName);
      }
    });
  },
};
